<template>
  <div ref="wrapper" class="rounded bg-gray-100 p-2 mb-2">
    <Renderer
      :ast-string="dataCondition"
      :builder="builder"
      :attributes="attributes"
      :values="values"
      :show-widgets="true"
      @change="onRendererUpdate"
    />
    <div class="human-ast" v-text="humanReadableAST"></div>
    <div class="flex gap-x-2">
      <AButton :label="'Apply'" @click="onApply" />
      <AButton
        :color="'secondary'"
        :shade="50"
        :text-color="'black'"
        :label="'Close'"
        @click="onClose"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, provide } from 'vue'
import AButton from '../_abstract/AButton.vue'
import CustomConditionBuilder from '../ast/components/CustomConditionBuilder.vue'
import { documentStates, workflowValues } from './store'
import { DOMEmit } from '../dom_utils'
import Ast from '../ast/ast'
import StringUtils from '../string_utils'

const props = withDefaults(
  defineProps<{
    astString?: string
    title?: string
    attributes?: string[]
    values?: string[]
    type: 'date' | 'system' | 'states' | 'attributes' | 'workflow'
    populateFormInput?: boolean
    formInputSelector?: string
  }>(),
  {
    astString: `{'ast': {"Equals": [{"Att": ""}, {"String": "Yes"}]}}`,
    title: 'Condition',
    attributes: () => []
  }
)

const emit = defineEmits(['apply', 'close'])
const wrapper = ref()

const Renderer = window.Ast.Renderer,
  ConditionBuilder = window.Ast.Builders.condition,
  AstBuilder = window.Ast.Builders.ast,
  AvvSelect = window.AvvSelect
const dataCondition = ref(props.astString)

const onRendererUpdate = (payload: any) => (dataCondition.value = payload.ast)

const onApply = () => {
  emit('apply', dataCondition.value)
  DOMEmit('apply-condition-builder', {
    astString: dataCondition.value,
    wrapper: wrapper.value
  })
  if (props.populateFormInput) populateInput(dataCondition.value)
}

const onClose = () => {
  DOMEmit('close-condition-builder', { wrapper: wrapper.value })
  emit('close')
}

const populateInput = (astString: string) => {
  if (!props.formInputSelector) return
  const input = wrapper.value.closest(props.formInputSelector)
    .nextElementSibling as HTMLInputElement
  const isEmpty =
    StringUtils.removeSpaces(astString) ==
    StringUtils.removeSpaces(Ast.EMPTY_AST_STRING)
  input.value = isEmpty ? '' : astString
}

const humanReadableAST = computed(() => {
  return (
    window.Ast.astStringToHumanReadable(dataCondition.value, false, true) ??
    'Empty'
  )
})

const builder = computed(() => {
  if (props.type == 'date') return CustomConditionBuilder
  if (props.type == 'attributes') return ConditionBuilder
  if (props.type == 'system') return CustomConditionBuilder
  if (props.type == 'states') return CustomConditionBuilder
  if (props.type == 'workflow') return CustomConditionBuilder
})

const values = computed(() => {
  if (props.type == 'states') return Object.values(documentStates)
  else if (props.type == 'workflow') return workflowValues
  else return props.values
})

provide('type', props.type)
</script>
